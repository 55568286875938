/* ---------------------------create-uncertified-product-action---------------------------*/

/* ------------------------Get Brand Listing-----------------------*/
export const GET_TARGET_MARKET_REQUEST = 'GET_TARGET_MARKET_REQUEST';
export const GET_TARGET_MARKET_SUCCESS_REQUEST = 'GET_TARGET_MARKET_SUCCESS_REQUEST';
export const GET_TARGET_MARKET_FAILURE_REQUEST = 'GET_TARGET_MARKET_FAILURE_REQUEST';

/* ------------------------Get subcodes-----------------------*/
export const GET_SUBCODES_REQUEST = 'GET_SUBCODES_REQUEST';
export const GET_SUBCODES_SUCCESS_REQUEST = 'GET_SUBCODES_SUCCESS_REQUEST';
export const GET_SUBCODES_FAILURE_REQUEST = 'GET_SUBCODES_FAILURE_REQUEST';

/* ------------------------Get Licensed subcodes-----------------------*/
export const GET_LICENSED_SUBCODES_REQUEST = 'GET_LICENSED_SUBCODES_REQUEST';
export const GET_LICENSED_SUBCODES_SUCCESS_REQUEST = 'GET_LICENSED_SUBCODES_SUCCESS_REQUEST';
export const GET_LICENSED_SUBCODES_FAILURE_REQUEST = 'GET_LICENSED_SUBCODES_FAILURE_REQUEST';

/* ------------------------Get brandList-----------------------*/
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS_REQUEST = 'GET_BRANDS_SUCCESS_REQUEST';
export const GET_BRANDS_FAILURE_REQUEST = 'GET_BRANDS_FAILURE_REQUEST';

/* ------------------------Get Category-----------------------*/
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS_REQUEST = 'GET_CATEGORY_SUCCESS_REQUEST';
export const GET_CATEGORY_FAILURE_REQUEST = 'GET_CATEGORY_FAILURE_REQUEST';

/* ----------------------------edit-uncertified-product-action-----------------------------*/

/* ------------------------Get getTamplate-data-----------------------*/
export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_SUCCESS_REQUEST = 'GET_TEMPLATE_SUCCESS_REQUEST';
export const GET_TEMPLATE_FAILURE_REQUEST = 'GET_TEMPLATE_FAILURE_REQUEST';

/* ------------------------Get-prodoct-data-----------------------*/
export const GET_PRODUCT_DATA_REQUEST = 'GET_PRODUCT_DATA_REQUEST';
export const GET_PRODUCT_DATA_SUCCESS_REQUEST = 'GET_PRODUCT_DATA_SUCCESS_REQUEST';
export const GET_PRODUCT_DATA_FALIURE_REQUEST = 'GET_PRODUCT_DATA_FALIURE_REQUEST';

/* ----------------------------uncertified-product-list-action-----------------------------*/

/* ------------------------Get-uncertified-prodoct-list-----------------------*/
export const GET_UNCERTIFIED_PRODUCT_LIST_REQUEST = 'GET_UNCERTIFIED_PRODUCT_LIST_REQUEST';
export const GET_UNCERTIFIED_PRODUCT_LIST_SUCCESS = 'GET_UNCERTIFIED_PRODUCT_LIST_SUCCESS';
export const GET_UNCERTIFIED_PRODUCT_LIST_FALIURE = 'GET_UNCERTIFIED_PRODUCT_LIST_FALIURE';

export const GET_D_CATEGORY_REQUEST = 'GET_D_CATEGORY_REQUEST';
export const GET_D_CATEGORY_SUCCESS_REQUEST = 'GET_D_CATEGORY_SUCCESS_REQUEST';
export const GET_D_CATEGORY_FAILURE_REQUEST = 'GET_D_CATEGORY_FAILURE_REQUEST';

export const GET_UNCERTIFIED_STATUS_REQUEST = 'GET_UNCERTIFIED_STATUS_REQUEST';
export const GET_UNCERTIFIED_STATUS_SUCCESS_REQUEST = 'GET_UNCERTIFIED_STATUS_SUCCESS_REQUEST';
export const GET_UNCERTIFIED_STATUS_FAILURE_REQUEST = 'GET_UNCERTIFIED_STATUS_FAILURE_REQUEST';
/* ------------------------uncertified-prodoct-asset-----------------------*/

// product asset upload
export const UNCERTIFIED_PRODUCT_ASSET_UPLOAD_REQUEST = 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_REQUEST';
export const UNCERTIFIED_PRODUCT_ASSET_UPLOAD_SUCCESS = 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_SUCCESS';
export const UNCERTIFIED_PRODUCT_ASSET_UPLOAD_FAILURE = 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_FAILURE';

// product image validation
export const UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_REQUEST = 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_REQUEST';
export const UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_SUCCESS = 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_SUCCESS';
export const UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_FAILURE = 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_FAILURE';

// product image list request
export const GET_UNCERTIFIED_PRODUCT_IMAGES_REQUEST = 'GET_UNCERTIFIED_PRODUCT_IMAGES_REQUEST';
export const GET_UNCERTIFIED_PRODUCT_IMAGES_SUCCESS = 'GET_UNCERTIFIED_PRODUCT_IMAGES_SUCCESS';
export const GET_UNCERTIFIED_PRODUCT_IMAGES_FAILURE = 'GET_UNCERTIFIED_PRODUCT_IMAGES_FAILURE';

// product document list request
export const GET_UNCERTIFIED_PRODUCT_DOCUMENTS_REQUEST = 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_REQUEST';
export const GET_UNCERTIFIED_PRODUCT_DOCUMENTS_SUCCESS = 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_SUCCESS';
export const GET_UNCERTIFIED_PRODUCT_DOCUMENTS_FAILURE = 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_FAILURE';

// product document type
export const GET_DOCUMENT_TYPE_REQUEST = 'GET_DOCUMENT_TYPE_REQUEST';
export const GET_DOCUMENT_TYPE_SUCCESS = 'GET_DOCUMENT_TYPE_SUCCESS';
export const GET_DOCUMENT_TYPE_FAILURE = 'GET_DOCUMENT_TYPE_FAILURE';

// product image type
export const GET_IMAGE_TYPE_REQUEST = 'GET_IMAGE_TYPE_REQUEST';
export const GET_IMAGE_TYPE_SUCCESS = 'GET_IMAGE_TYPE_SUCCESS';
export const GET_IMAGE_TYPE_FAILURE = 'GET_IMAGE_TYPE_FAILURE';

// product image or document exists
export const GET_ASSET_EXISTS_REQUEST = 'GET_ASSET_EXISTS_REQUEST';
export const GET_ASSET_EXISTS_SUCCESS = 'GET_ASSET_EXISTS_SUCCESS';
export const GET_ASSET_EXISTS_FAILURE = 'GET_ASSET_EXISTS_FAILURE';

/* ----------------------------get-language-list-action-----------------------------*/
export const GET_LANGUAGE_LIST_REQUEST = 'GET_LANGUAGE_LIST_REQUEST';
export const GET_LANGUAGE_LIST_SUCCESS = 'GET_LANGUAGE_LIST_SUCCESS';
export const GET_LANGUAGE_LIST_FAILURE = 'GET_LANGUAGE_LIST_FAILURE';

/* ----------------------------get-language-Update-action-----------------------------*/
export const GET_LANGUAGE_UPDATE_REQUEST = 'GET_LANGUAGE_UPDATE_REQUEST';
export const GET_LANGUAGE_UPDATE_SUCCESS = 'GET_LANGUAGE_UPDATE_SUCCESS';
export const GET_LANGUAGE_UPDATE_FAILURE = 'GET_LANGUAGE_UPDATE_FAILURE';

/* ----------------------------get-language-Update-action-----------------------------*/
export const GET_LANGUAGE_TRANSLATION_REQUEST = 'GET_LANGUAGE_TRANSLATION_REQUEST';
export const GET_LANGUAGE_TRANSLATION_SUCCESS = 'GET_LANGUAGE_TRANSLATION_SUCCESS';
export const GET_LANGUAGE_TRANSLATION_FAILURE = 'GET_LANGUAGE_TRANSLATION_FAILURE';

// asset delete
export const GET_DELETE_ASSET_REQUEST = 'GET_DELETE_ASSET_REQUEST';
export const GET_DELETE_ASSET_SUCCESS = 'GET_DELETE_ASSET_SUCCESS';
export const GET_DELETE_ASSET_FAILURE = 'GET_DELETE_ASSET_FAILURE';

/* -------------------structured-nutrition-actions-----------------------*/

export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS_REQUEST = 'GET_UNITS_SUCCESS_REQUEST';
export const GET_UNITS_FAILURE_REQUEST = 'GET_UNITS_FAILURE_REQUEST';

export const GET_QUALIFIERS_REQUEST = 'GET_QUALIFIERS_REQUEST';
export const GET_QUALIFIERS_SUCCESS_REQUEST = 'GET_QUALIFIERS_SUCCESS_REQUEST';
export const GET_QUALIFIERS_FAILURE_REQUEST = 'GET_QUALIFIERS_FAILURE_REQUEST';
// assets progress bar
export const SET_ASSET_UPLOAD_PROGRESS = 'SET_ASSET_UPLOAD_PROGRESS';
export const CLEAR_PROGRESS_BAR = 'CLEAR_PROGRESS_BAR';
export const IS_DISABLE_PROGRESS_SAVE_BUTTON = 'IS_DISABLE_PROGRESS_SAVE_BUTTON';

export const GET_PRODUCT_BASIC_DATA_REQUEST = 'GET_PRODUCT_BASIC_DATA_REQUEST';
export const GET_PRODUCT_BASIC_DATA_SUCCESS = 'GET_PRODUCT_BASIC_DATA_SUCCESS';
export const GET_PRODUCT_BASIC_DATA_FAILURE = 'GET_PRODUCT_BASIC_DATA_FAILURE';

// image shot type by selected uncertified product
export const GET_ASSET_TYPE_BY_SELECTED_PRODUCT_REQUEST = 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_REQUEST';
export const GET_ASSET_TYPE_BY_SELECTED_PRODUCT_SUCCESS = 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_SUCCESS';
export const GET_ASSET_TYPE_BY_SELECTED_PRODUCT_FAILURE = 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_FAILURE';

/* --------------------Get-Product-Comments---------------------------------*/
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS_REQUEST = 'GET_COMMENTS_SUCCESS_REQUEST';
export const GET_COMMENTS_FAILURE_REQUEST = 'GET_COMMENTS_FAILURE_REQUEST';

/* -------------------Get-Recent-Edited-Product---------------------------------*/
export const GET_SDE_RECENT_EDITED_PRODUCTS_REQUEST = 'GET_SDE_RECENT_EDITED_PRODUCTS_REQUEST';
export const GET_SDE_RECENT_EDITED_PRODUCTS_SUCCESS = 'GET_SDE_RECENT_EDITED_PRODUCTS_SUCCESS';
export const GET_SDE_RECENT_EDITED_PRODUCTS_FAILURE = 'GET_SDE_RECENT_EDITED_PRODUCTS_FAILURE';

/* -------------------Get-Pending-Approval-Product---------------------------------*/
export const GET_SDE_LATEST_PENDING_APPROVAL_REQUEST = 'GET_SDE_LATEST_PENDING_APPROVAL_REQUEST';
export const GET_SDE_LATEST_PENDING_APPROVAL_SUCCESS = 'GET_SDE_LATEST_PENDING_APPROVAL_SUCCESS';
export const GET_SDE_LATEST_PENDING_APPROVAL_FAILURE = 'GET_SDE_LATEST_PENDING_APPROVAL_FAILURE';

/* -------------------Get-Count-Product---------------------------------*/
export const GET_COUNT_PRODUCT_REQUEST = 'GET_COUNT_PRODUCT_REQUEST';
export const GET_COUNT_PRODUCT_SUCCESS_REQUEST = 'GET_COUNT_PRODUCT_SUCCESS_REQUEST';
export const GET_COUNT_PRODUCT_FAILURE_REQUEST = 'GET_COUNT_PRODUCT_FAILURE_REQUEST';

export const UPDATE_ASSET_PROGRESS_BAR_LIST = 'UPDATE_ASSET_PROGRESS_BAR_LIST';

/* --------------------get-user-details-actions---------------------------------*/

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS_REQUEST = 'GET_USER_DETAILS_SUCCESS_REQUEST';
export const GET_USER_DETAILS_FAILURE_REQUEST = 'GET_USER_DETAILS_FAILURE_REQUEST';

/* --------------------connect plus actions---------------------------------*/
export const GET_CONNECT_PLUS_REQUEST = 'GET_CONNECT_PLUS_REQUEST';
export const GET_CONNECT_PLUS_SUCCESS_REQUEST = 'GET_CONNECT_PLUS_SUCCESS_REQUEST';
export const GET_CONNECT_PLUS_FAILURE_REQUEST = 'GET_CONNECT_PLUS_FAILURE_REQUEST';
/* ----------------------------get-user-language-----------------------------*/
export const GET_USER_LANGUAGE_REQUEST = 'GET_USER_LANGUAGE_REQUEST';
export const GET_USER_LANGUAGE_SUCCESS = 'GET_USER_LANGUAGE_SUCCESS';
export const GET_USER_LANGUAGE_FAILURE = 'GET_USER_LANGUAGE_FAILURE';

/* ----------------------------get-marketing-content-----------------------------*/
export const GET_MARKETING_CONTENT_REQUEST = 'GET_MARKETING_CONTENT_REQUEST';
export const GET_MARKETING_CONTENT_SUCCESS = 'GET_MARKETING_CONTENT_SUCCESS';
export const GET_MARKETING_CONTENT_FAILURE = 'GET_MARKETING_CONTENT_FAILURE';

/* --------------------monitor notifications---------------------------------*/

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS_REQUEST = 'GET_NOTIFICATIONS_SUCCESS_REQUEST';
export const GET_NOTIFICATIONS_FAILURE_REQUEST = 'GET_NOTIFICATIONS_FAILURE_REQUEST';

export const NOTIFICATION_COUNT_MINUS_ONE = 'NOTIFICATION_COUNT_MINUS_ONE';

/* --------------------get-mail-actions---------------------------------*/

export const GET_CONNECT_PLUS_MAIL_REQUEST = 'GET_CONNECT_PLUS_MAIL_REQUEST';
export const GET_CONNECT_PLUS_MAIL_SUCCESS_REQUEST = 'GET_CONNECT_PLUS_MAIL_SUCCESS_REQUEST';
export const GET_CONNECT_PLUS_MAIL_FAILURE_REQUEST = 'GET_CONNECT_PLUS_MAIL_FAILURE_REQUEST';

/* -----------------------get-service-message---------------------------*/
export const GET_SERVICE_MESSAGE_REQUEST = 'GET_SERVICE_MESSAGE_REQUEST';
export const GET_SERVICE_MESSAGE_SUCCESS = 'GET_SERVICE_MESSAGE_SUCCESS';
export const GET_SERVICE_MESSAGE_FAILURE = 'GET_SERVICE_MESSAGE_FAILURE';

/* -----------------------------------------------Certified-product-----------------------------------------------*/

/* ------------------------certified-prodoct-data-actions-----------------------*/
export const GET_CERTIFIED_PRODUCT_DATA_REQUEST = 'GET_CERTIFIED_PRODUCT_DATA_REQUEST';
export const GET_CERTIFIED_PRODUCT_DATA_SUCCESS = 'GET_CERTIFIED_PRODUCT_DATA_SUCCESS';
export const GET_CERTIFIED_PRODUCT_DATA_FALIURE = 'GET_CERTIFIED_PRODUCT_DATA_FALIURE';

/* ------------------------certified-prodoct-image-and-document-actions-----------------------*/
export const GET_CERTIFIED_PRODUCT_IMAGES_REQUEST = 'GET_CERTIFIED_PRODUCT_IMAGES_REQUEST';
export const GET_CERTIFIED_PRODUCT_IMAGES_SUCCESS = 'GET_CERTIFIED_PRODUCT_IMAGES_SUCCESS';
export const GET_CERTIFIED_PRODUCT_IMAGES_FAILURE = 'GET_CERTIFIED_PRODUCT_IMAGES_FAILURE';

export const GET_POST_PUBLICATION_IMAGES_REQUEST = 'GET_POST_PUBLICATION_IMAGES_REQUEST';
export const GET_POST_PUBLICATION_PRODUCT_IMAGES_SUCCESS = 'GET_POST_PUBLICATION_PRODUCT_IMAGES_SUCCESS';
export const GET_POST_PUBLICATION_PRODUCT_IMAGES_FAILURE = 'GET_POST_PUBLICATION_PRODUCT_IMAGES_FAILURE';

export const GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_REQUEST = 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_REQUEST';
export const GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_SUCCESS = 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_SUCCESS';
export const GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_FAILURE = 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_FAILURE';

export const GET_CERTIFIED_PRODUCT_DOCUMENTS_REQUEST = 'GET_CERTIFIED_PRODUCT_DOCUMENTS_REQUEST';
export const GET_CERTIFIED_PRODUCT_DOCUMENTS_SUCCESS = 'GET_CERTIFIED_PRODUCT_DOCUMENTS_SUCCESS';
export const GET_CERTIFIED_PRODUCT_DOCUMENTS_FAILURE = 'GET_CERTIFIED_PRODUCT_DOCUMENTS_FAILURE';

/* ------------------------certified-prodoct-list-actions---------------------------------*/
export const GET_CERTIFIED_PRODUCT_LIST_REQUEST = 'GET_CERTIFIED_PRODUCT_LIST_REQUEST';
export const GET_CERTIFIED_PRODUCT_LIST_SUCCESS = 'GET_CERTIFIED_PRODUCT_LIST_SUCCESS';
export const GET_CERTIFIED_PRODUCT_LIST_FALIURE = 'GET_CERTIFIED_PRODUCT_LIST_FALIURE';

/* ------------------------certified-product-assetsType-actions---------------------------------*/
export const GET_CERTIFIED_PRODUCT_ASSET_TYPE_REQUEST = 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_REQUEST';
export const GET_CERTIFIED_PRODUCT_ASSET_TYPE_SUCCESS = 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_SUCCESS';
export const GET_CERTIFIED_PRODUCT_ASSET_TYPE_FAILURE = 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_FAILURE';

/* ------------------------certified-category-filter-listing-actions---------------------------------*/
export const GET_CERTIFIED_CATEGORY_REQUEST = 'GET_CERTIFIED_CATEGORY_REQUEST';
export const GET_CERTIFIED_CATEGORY_SUCCESS_REQUEST = 'GET_CERTIFIED_CATEGORY_SUCCESS_REQUEST';
export const GET_CERTIFIED_CATEGORY_FAILURE_REQUEST = 'GET_CERTIFIED_CATEGORY_FAILURE_REQUEST';

/* ------------------------certified-status-filter-listing-actions---------------------------------*/
export const GET_CERTIFIED_STATUS_REQUEST = 'GET_CERTIFIED_STATUS_REQUEST';
export const GET_CERTIFIED_STATUS_SUCCESS_REQUEST = 'GET_CERTIFIED_STATUS_SUCCESS_REQUEST';
export const GET_CERTIFIED_STATUS_FAILURE_REQUEST = 'GET_CERTIFIED_STATUS_FAILURE_REQUEST';

// -------------------------add-and-delete-retailer-actions--------------------------------------

export const HANDLE_ADD_RETAILER = 'HANDLE_ADD_RETAILER';
export const HANDLE_DELETE_RETAILER = 'HANDLE_DELETE_RETAILER';
export const HANDLE_REMOVE_ALL_RETAILER = 'HANDLE_REMOVE_ALL_RETAILER';

// ----------------Completeness Report----------------
export const GET_COMPLETENESS_REPORT_REQUEST = 'GET_COMPLETENESS_REPORT_REQUEST';
export const GET_COMPLETENESS_REPORT_SUCCESS_REQUEST = 'GET_COMPLETENESS_REPORT_SUCCESS_REQUEST';
export const GET_COMPLETENESS_REPORT_FAILURE_REQUEST = 'GET_COMPLETENESS_REPORT_FAILURE_REQUEST';

// -------------------------rsc-product-count-----------------------------------------------------

export const HANDLE_GET_DASHBOARD_RSC_COUNT = 'HANDLE_GET_DASHBOARD_RSC_COUNT';
export const HANDLE_GET_DASHBOARD_RSC_COUNT_SUCCESS = 'HANDLE_GET_DASHBOARD_RSC_COUNT_SUCCESS';
export const HANDLE_GET_DASHBOARD_RSC_COUNT_FAILURE = 'HANDLE_GET_DASHBOARD_RSC_COUNT_FAILURE';

export const GET_CERTIFIED_RECENT_EDITED_PRODUCTS_REQUEST = 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_REQUEST';
export const GET_CERTIFIED_RECENT_EDITED_PRODUCTS_SUCCESS = 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_SUCCESS';
export const GET_CERTIFIED_RECENT_EDITED_PRODUCTS_FAILURE = 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_FAILURE';

export const GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_REQUEST = 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_REQUEST';
export const GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_SUCCESS = 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_SUCCESS';
export const GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_FAILURE = 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_FAILURE';

// ----------------------------target-usage-option-action---------------------
export const GET_TARGET_USAGE_OPTION_REQUEST = 'GET_TARGET_USAGE_OPTION_REQUEST';
export const GET_TARGET_USAGE_OPTION_SUCCESS = 'GET_TARGET_USAGE_OPTION_SUCCESS';
export const GET_TARGET_USAGE_OPTION_FAILURE = 'GET_TARGET_USAGE_OPTION_FAILURE';

// --------------------list-new-actions-----------------------------------
export const GET_FILTER_DATA_REQUEST = 'GET_FILTER_DATA_REQUEST';
export const GET_FILTER_DATA_SUCCESS = 'GET_FILTER_DATA_SUCCESS';
export const GET_FILTER_DATA_FAILURE = 'GET_FILTER_DATA_FAILURE';

// ---------------------------------------nbb------------------------------------------------------

export const HANDLE_IS_OPEN_VALIDATION_MODAL = 'HANDLE_IS_OPEN_VALIDATION_MODAL';
export const HANDLE_RENDER_ATTRIBUTE = 'HANDLE_RENDER_ATTRIBUTE';
export const HANDLE_IGNORE_WARNINGS = 'HANDLE_IGNORE_WARNINGS';

export const HANDLE_CREATE_EDIT_VIEW = 'HANDLE_CREATE_EDIT_VIEW';

export const GET_AWAITING_BRANDBANK_CAPTURED_LIST_REQUEST = 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_REQUEST';
export const GET_AWAITING_BRANDBANK_CAPTURED_LIST_SUCCESS = 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_SUCCESS';
export const GET_AWAITING_BRANDBANK_CAPTURED_LIST_FALIURE = 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_FALIURE';

export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_REQUEST = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_REQUEST';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_SUCCESS = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_SUCCESS';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_FAILURE = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_FAILURE';

export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_REQUEST = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_REQUEST';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_SUCCESS = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_SUCCESS';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_FAILURE = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_FAILURE';

export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_REQUEST = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_REQUEST';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_SUCCESS = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_SUCCESS';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_FAILURE = 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_FAILURE';

export const GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_REQUEST = 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_REQUEST';
export const GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_SUCCESS = 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_SUCCESS';
export const GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_FAILURE = 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_FAILURE';

// -------------to-store-nbb-product-filter-------------------------------------
export const SET_NBB_PRODUCT_FILTER = 'SET_NBB_PRODUCT_FILTER';

// -------------to-return-to-product-list-from-delist-screen-------------------------------------
export const HANDLE_RETURN_TO_PRODUCT_LIST = 'HANDLE_RETURN_TO_PRODUCT_LIST';

// ---------------------------------reports-actions-------------------------------------------

export const GET_PUBLICATION_SUMMARY_REPORTS = 'GET_PUBLICATION_SUMMARY_REPORTS';
export const GET_PUBLICATION_SUMMARY_REPORTS_SUCCESS = 'GET_PUBLICATION_SUMMARY_REPORTS_SUCCESS';
export const GET_PUBLICATION_SUMMARY_REPORTS_FAILURE = 'GET_PUBLICATION_SUMMARY_REPORTS_FAILURE';

export const GET_PUBLICATION_DETAIL_REPORTS = 'GET_PUBLICATION_DETAIL_REPORTS';
export const GET_PUBLICATION_DETAIL_REPORTS_SUCCESS = 'GET_PUBLICATION_DETAIL_REPORTS_SUCCESS';
export const GET_PUBLICATION_DETAIL_REPORTS_FAILURE = 'GET_PUBLICATION_DETAIL_REPORTS_FAILURE';

export const GET_MERGE_DETAIL_REPORT = 'GET_MERGE_DETAIL_REPORT';
export const GET_MERGE_DETAIL_REPORT_SUCCESS = 'GET_MERGE_DETAIL_REPORT_SUCCESS';
export const GET_MERGE_DETAIL_REPORT_FAILURE = 'GET_MERGE_DETAIL_REPORT_FAILURE';

export const BLOCK_ADVANCE_FILTER_REQUEST = 'BLOCK_ADVANCE_FILTER_REQUEST';

export const SET_VALIDATION_MODAL_IS_CHANGE = 'SET_VALIDATION_MODAL_IS_CHANGE';

// ----------------------- CH+ actions ---------------
export const GET_CH_PLUS_REPORT_REQUEST = 'GET_CH_PLUS_REPORT_REQUEST';
export const GET_CH_PLUS_REPORT_SUCCESS_REQUEST = 'GET_CH_PLUS_REPORT_SUCCESS_REQUEST';
export const GET_CH_PLUS_REPORT_FAILURE_REQUEST = 'GET_CH_PLUS_REPORT_FAILURE_REQUEST';

export const GET_PRODUCT_RECALCULATE_SCORE = 'GET_PRODUCT_RECALCULATE_SCORE';

// --------------------------ImageTopup-actions-----------------------------

export const GET_PRODUCT_TOPUP_LAYOUTS = 'GET_PRODUCT_TOPUP_LAYOUTS';
export const GET_PRODUCT_TOPUP_LAYOUTS_SUCCESS = 'GET_PRODUCT_TOPUP_LAYOUTS_SUCCESS';
export const GET_PRODUCT_TOPUP_LAYOUTS_FAILURE = 'GET_PRODUCT_TOPUP_LAYOUTS_FAILURE';

export const GET_USER_UPLOADED_ASSET_REQUEST = 'GET_USER_UPLOADED_ASSET_REQUEST';
export const GET_USER_UPLOADED_ASSET_SUCCESS = 'GET_USER_UPLOADED_ASSET_SUCCESS';
export const GET_USER_UPLOADED_ASSET_FAILURE = 'GET_USER_UPLOADED_ASSET_FAILURE';


// -------------------------------------flexible extract-------------------------------------------------------

export const GET_CERTIFIED_ATTRIBUTE_DATA_REQUEST = "GET_CERTIFIED_ATTRIBUTE_DATA_REQUEST";
export const GET_CERTIFIED_ATTRIBUTE_DATA_SUCCESS = "GET_CERTIFIED_ATTRIBUTE_DATA_SUCCESS";
export const GET_CERTIFIED_ATTRIBUTE_DATA_FAILURE = "GET_CERTIFIED_ATTRIBUTE_DATA_FAILURE";

export const GET_IMAGE_CONFIGURATIONS_REQUEST = "GET_IMAGE_CONFIGURATIONS_REQUEST";
export const GET_IMAGE_CONFIGURATIONS_SUCCESS = "GET_IMAGE_CONFIGURATIONS_SUCCESS";
export const GET_IMAGE_CONFIGURATIONS_FAILURE = "GET_IMAGE_CONFIGURATIONS_FAILURE";

// //-------------------------------- Prev & Next Product ------------------------------
export const HANDLE_PREV_NEXT_REQUEST = "HANDLE_PREV_NEXT_REQUEST";
export const HANDLE_PREV_NEXT_SUCCESS = "HANDLE_PREV_NEXT_SUCCESS";
export const HANDLE_PREV_NEXT_FAILURE = "HANDLE_PREV_NEXT_FAILURE";
