import { TARGET_USAGE } from '../../constant/constant';

const initialState = {
  targetMarketList: [],
  subCodeList: [],
  brandList: [],
  categoryList: [],
  dynamicCategoryList: [],
  languageList: [],
  templateData: {},
  productData: {},
  products: {},
  getTranslateData: [],
  units: [],
  qualifiers: [],
  isLoading: false,
  fileProgress: {},
  productBasicData: [],
  productLoader: false,
  productCommentsList: [],
  userLoader: false,
  userDetails: {},
  connectPlusLoader: false,
  connectPlusData: {},
  recentlyEditedProduct: [],
  pendingApprovalProduct: [],
  countProduct: [],
  multipartData: null,
  multiparts: [],
  userLanguage: '',
  marketingData: [],
  listLoader: false,
  notifications: {},
  sendMail: {},
  licensedSubcodeList: [],
  addedRetailer: [],
  deletedRetailer: [],
  messageList: {},
  completenessData: {},
  RscBarData: [],
  recentEditedCertifiedProduct: [],
  latestCertifiedProduct: [],
  targetUsage: [],
  dynamicStatusList: [],
  retailersList: [],
  assetLoad: false,
  filterLoader: false,
  futurePublishDateFeatureSubcodes: [],
  advanceSearchLoader: false,
  postPublicationProductImages: {},
  postPublicationProductDocuments: {},

  // nbb--------------------------------------------------------------------------------
  isValidation: false,
  nbb_subcodeList: [],
  nbb_gpc_category: [],
  hybridProducts: [],
  advanceFilterData: {},
  validationData: [],
  attributeInfo: {},
  basicTempData: {},
  nbb_filter_data: {},
  cinLogMsg: {},
  cicLogMsg: {},
  publishStatusLog: {},
  nbbTargetMarketList: [],
  countProductChannel: [],
  recentEditedNbbProduct: [],
  brandbankCapturedData: [],
  countProductValidation: [],
  RscBarProductData: [],
  ignoreWarning: false,
  createEditView: false,
  currentNBBPage: 1,
  offset: 0,
  returnToProductList: false,
  retailers: [],
  template: [],
  componentTemplate: [],
  NbbSSAData: null,
  publicationReportData: {
    data: [],
  },
  publicatonDetailReport: {
    data: [],
  },
  mergeDtailReport: {
    data: [],
  },
  reportLoader: false,
  blockAdvanceFilterDropdown: false,
  codeList: {},
  groups: {},
  validationJson: {},
  errorMsgTranslations: {},
  publishDateFeatureSubcode: [],
  isProductExist: { message: '', isExist: true, isFetched: false },
  isValidationDataChange: false,
  // --------- CH+----------
  contentHealthAndRankData: {},
  chpScore: {},
  imageTopupSavedLayouts: [],
  userUploadedAsset: [],
  certifiedAttributeData: [],
  imageConfiguration: null,
  imageDimensionOpt: [],
  prevNextProductData: [],
  prevNextLoading: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VALIDATION_MODAL_IS_CHANGE': {
      return {
        ...state,
        isValidationDataChange: action.data,
      };
    }
    // --------------------------codelist-data------------------------------
    case 'GET_CODELIST_REQUEST': {
      return {
        ...state,
      };
    }
    case 'GET_CODELIST_SUCCESS_REQUEST': {
      return {
        ...state,
        codeList: action.data,
      };
    }
    case 'GET_CODELIST_FAILURE_REQUEST': {
      return {
        ...state,
        codeList: {},
      };
    }
    // --------------------------validation-data------------------------------
    case 'GET_VALIDATION_DATA_REQUEST': {
      return {
        ...state,
      };
    }
    case 'GET_VALIDATION_DATA_SUCCESS_REQUEST': {
      return {
        ...state,
        validationJson: action.data,
      };
    }

    case 'GET_VALIDATION_DATA_FAILURE_REQUEST': {
      return {
        ...state,
        validationJson: {},
      };
    }
    // --------------------------validation-label-data------------------------------
    case 'GET_VALIDATION_LABEL_REQUEST': {
      return {
        ...state,
      };
    }
    case 'GET_VALIDATION_LABEL_SUCCESS_REQUEST': {
      return {
        ...state,
        errorMsgTranslations: action.data,
      };
    }
    case 'GET_VALIDATION_LABEL_FAILURE_REQUEST': {
      return {
        ...state,
        errorMsgTranslations: {},
      };
    }
    // ------------------------------gpc static data--------------------------------------
    case 'GET_GPC_STATIC_FILE_REQUEST': {
      return {
        ...state,
      };
    }
    case 'GET_GPC_STATIC_FILE__SUCCESS_REQUEST': {
      return {
        ...state,
        nbb_gpc_category: action.data,
      };
    }
    case 'GET_GPC_STATIC_FILE_FAILURE_REQUEST': {
      return {
        ...state,
        nbb_gpc_category: [],
      };
    }

    // --------------------------groups-static-data------------------------------
    case 'GET_GROUPS_REQUEST': {
      return {
        ...state,
      };
    }
    case 'GET_GROUPS_SUCCESS_REQUEST': {
      return {
        ...state,
        groups: action.data,
      };
    }
    case 'GET_GROUPS_FAILURE_REQUEST': {
      return {
        ...state,
        groups: {},
      };
    }
    // -------------------------------------------------------------------------------

    case 'BLOCK_ADVANCE_FILTER_REQUEST':
      {
        return {
          ...state,
          blockAdvanceFilterDropdown: action.data,
        };
      }
    case 'HANDLE_ADD_RETAILER':
      {
        const addRetailerArr = state.addedRetailer.concat(action.data.retailerId);
        const filterDeletedRetailerArr = state.deletedRetailer.filter((value) => value !== action.data.retailerId);
        return {
          ...state,
          addedRetailer: addRetailerArr,
          deletedRetailer: filterDeletedRetailerArr,
        };
      }

    case 'HANDLE_DELETE_RETAILER':
      {
        const filterAddedRetailerArr = state.addedRetailer.filter((value) => value !== action.data.retailerId);
        const deletedRetailerArr = state.deletedRetailer.concat(action.data.retailerId);
        return {
          ...state,
          addedRetailer: filterAddedRetailerArr,
          deletedRetailer: deletedRetailerArr,
        };
      }

    case 'HANDLE_REMOVE_ALL_RETAILER': {
      return {
        ...state,
        addedRetailer: [],
        deletedRetailer: [],
      };
    }

    case 'NOTIFICATION_COUNT_MINUS_ONE':
      {
        const tempNotifications = { ...state.notifications, unreadCount: state.notifications.unreadCount - 1 };
        return {
          ...state,
          notifications: tempNotifications,
        };
      }

    /* ---------------------hande-save-user-details-------------------------*/
    case 'GET_NOTIFICATIONS':
      {
        return {
          ...state,
        };
      }
    case 'GET_NOTIFICATIONS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          notifications: action.n,
        };
      }

    case 'GET_NOTIFICATIONS_FAILURE_REQUEST':
      {
        return {
          ...state,
          // notifications: action.error
        };
      }

    /* ---------------------hande-mail-------------------------*/
    case 'GET_CONNECT_PLUS_MAIL_REQUEST':
      {
        return {
          ...state,
        };
      }
    case 'GET_CONNECT_PLUS_MAIL_SUCCESS_REQUEST':
      {
        return {
          ...state,
          sendMail: action.n,
        };
      }

    case 'GET_CONNECT_PLUS_MAIL_FAILURE_REQUEST':
      {
        return {
          ...state,
        };
      }

    /* ---------------------hande-save-user-details-------------------------*/
    case 'GET_USER_DETAILS_REQUEST':
      {
        return {
          ...state,
          userLoader: true,
        };
      }
    case 'GET_USER_DETAILS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          userDetails: action.user,
          userLoader: false,
        };
      }

    case 'GET_USER_DETAILS_FAILURE_REQUEST':
      {
        return {
          ...state,
          userDetails: action.error,
          userLoader: false,
        };
      }

    /* ---------------------CONNECT PLUS-------------------------*/

    case 'GET_CONNECT_PLUS_REQUEST':
      {
        return {
          ...state,
          connectPlusLoader: true,
        };
      }
    case 'GET_CONNECT_PLUS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          connectPlusData: action.user,
          connectPlusLoader: false,
        };
      }

    case 'GET_CONNECT_PLUS_FAILURE_REQUEST':
      {
        return {
          ...state,
          connectPlusData: action,
          connectPlusLoader: false,
        };
      }
    /* ---------------------service-message-navbar-------------------------*/
    case 'GET_SERVICE_MESSAGE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_SERVICE_MESSAGE_SUCCESS':
      {
        return {
          ...state,
          messageList: action.messageList,
          isLoading: false,
        };
      }

    case 'GET_SERVICE_MESSAGE_FAILURE':
      {
        return {
          ...state,
          messageList: action.error,
          isLoading: false,
        };
      }

    /* ------------language-list-data-navbar------------*/
    case 'GET_LANGUAGE_LIST_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_LANGUAGE_LIST_SUCCESS':
      {
        return {
          ...state,
          languageList: action.languageList.languages,
          isLoading: false,
        };
      }

    case 'GET_LANGUAGE_LIST_FAILURE':
      {
        return {
          ...state,
          languageList: action.error,
          isLoading: false,
        };
      }

    /* -------------------------create-uncertified-product-states-------------------*/

    /* ---------------------------------save-brandsList-----------------------------*/
    case 'GET_BRANDS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_BRANDS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          brandList: action.brand_list,
          isLoading: false,
        };
      }
    case 'GET_BRANDS_FAILURE_REQUEST':
      {
        return {
          ...state,
          brandList: action.error,
          isLoading: false,
        };
      }

    /* ---------------------------------Category-----------------------------*/
    case 'GET_CATEGORY_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CATEGORY_SUCCESS_REQUEST':
      {
        return {
          ...state,
          categoryList: action.categoryData,
          isLoading: false,
        };
      }
    case 'GET_CATEGORY_FAILURE_REQUEST':
      {
        return {
          ...state,
          categoryList: [],
          isLoading: false,
        };
      }

    /* ---------------------------------Status-----------------------------*/
    case 'GET_UNCERTIFIED_STATUS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_UNCERTIFIED_STATUS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          dynamicStatusList: action.dynamicStatusList,
          isLoading: false,
        };
      }
    case 'GET_UNCERTIFIED_STATUS_FAILURE_REQUEST':
      {
        return {
          ...state,
          dynamicStatusList: action.error,
          isLoading: false,
        };
      }

    /* -------------------------edit-uncertified-product-states-------------------*/

    /* -----------------------get-template-data-----------------*/
    case 'GET_TEMPLATE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_TEMPLATE_SUCCESS_REQUEST':
      {
        return {
          ...state,
          templateData: action.template_data,
          isLoading: false,
        };
      }
    case 'GET_TEMPLATE_FAILURE_REQUEST':
      {
        return {
          ...state,
          templateData: action.error,
          isLoading: false,
        };
      }

    /* -----------------------get-product-data-----------------*/
    case 'GET_PRODUCT_DATA_REQUEST':
      {
        return {
          ...state,
          productLoader: true,
        };
      }
    case 'GET_PRODUCT_DATA_SUCCESS_REQUEST':
      {
        return {
          ...state,
          productData: action.product,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: false },
        };
      }
    case 'GET_PRODUCT_DATA_FALIURE_REQUEST':
      {
        if (action?.error?.response && action?.error?.response?.status === 404 && action?.error?.response?.data?.msg) {
          return {
            ...state,
            productData: action.error,
            productLoader: false,
            isProductExist: { message: action.error.response.data.msg, isExist: false, isFetched: true },
          };
        }
        return {
          ...state,
          productData: action.error,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: true },
        };
      }

    /* -----------------------get-certified-product-data-----------------*/

    case 'GET_CERTIFIED_PRODUCT_DATA_REQUEST':
      {
        return {
          ...state,
          productLoader: true,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_DATA_SUCCESS':
      {
        return {
          ...state,
          productData: action.product,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: false },
        };
      }
    case 'GET_CERTIFIED_PRODUCT_DATA_FALIURE':
      {
        if (action?.error?.response && action?.error?.response?.status === 404 && action?.error?.response?.data?.msg) {
          return {
            ...state,
            productData: action.error,
            productLoader: false,
            isProductExist: { message: action.error.response.data.msg, isExist: false, isFetched: true },
          };
        }
        return {
          ...state,
          productData: action.error,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: true },
        };
      }

    /* -------------------------uncertified-product-list-states-------------------*/

    /* -----------------------save-uncertified-product-list-----------------*/
    case 'GET_UNCERTIFIED_PRODUCT_LIST_REQUEST':
      {
        return {
          ...state,
          listLoader: true,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_LIST_SUCCESS':
      {
        return {
          ...state,
          products: action.productList,
          listLoader: false,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_LIST_FALIURE':
      {
        return {
          ...state,
          products: action.error,
          listLoader: false,
        };
      }

    /* ------------ uncertified product asset start ----------------------*/
    case 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_REQUEST':
      {
        return {
          ...state,
          isLoading: false,
        };
      }
    case 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_SUCCESS':
      {
        return {
          ...state,
          existsStatus: false,
          imageValidationStatus: false,
          assetStatus: action.payload.successResponse,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: 1,
              type: action.payload.type,
            },
          },
        };
      }
    case 'UNCERTIFIED_PRODUCT_ASSET_UPLOAD_FAILURE':
      {
        return {
          ...state,
          existsStatus: false,
          imageValidationStatus: false,
          assetStatus: action.payload.errorResponse,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: action.payload.errorMsg === 'time_out' ? 10 : 2,
              type: action.payload.type,
              errorMsg: action.payload.errorMsg,
              progress: 0,
            },
          },
          isLoading: false,
        };
      }
    case 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_REQUEST':
      {
        return {
          ...state,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: 3,
              type: 'image',
            },
          },
          isLoading: false,
        };
      }
    case 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_REQUEST_PROCESS':
      {
        return {
          ...state,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: 3,
              type: 'image',
            },
          },
          isLoading: false,
        };
      }
    case 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_SUCCESS':
      {
        return {
          ...state,
          imageValidationStatus: action.payload.successResponse,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: 4,
              type: 'image',
            },
          },
          /* isLoading: false, */
        };
      }
    case 'UNCERTIFIED_PRODUCT_IMAGE_VALIDATION_FAILURE':
      {
        return {
          ...state,
          imageValidationStatus: action.payload.errorResponse,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.jobId]: {
              ...state.fileProgress[action.payload.jobId],
              status: action.payload.errorResponse === 'time_out' ? 10 : 5,
              imgValErrorMsg: action.payload.errorResponse,
              progress: 0,
              errorMsg: action.payload.errorResponse === 'time_out' ? 'time_out' : '',
            },
          },
        };
      }
    case 'SET_ASSET_UPLOAD_PROGRESS':
      {
        return {
          ...state,
          fileProgress: {
            ...state.fileProgress,
            [action.payload.id]: {
              ...state.fileProgress[action.payload.id],
              file: action.payload.file,
              type: action.payload.type,
              status: action.payload.status,
              progress: action.payload.progress,
              productId: action.payload.productId,
              typeId: action.payload.typeId,
              typeLabel: action.payload.typeLabel,
              gtin: action.payload.gtin,
              jobId: action.payload.id,
            },
          },
        };
      }
    case 'UPDATE_ASSET_PROGRESS_BAR_LIST':
      {
        return {
          ...state,
          fileProgress: action.data,
        };
      }
    case 'CLEAR_PROGRESS_BAR':
      {
        return {
          ...state,
          fileProgress: action.data,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_IMAGES_REQUEST':
      {
        return {
          ...state,
          assetLoad: true,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_IMAGES_SUCCESS':
      {
        return {
          ...state,
          productImages: action.images,
          assetLoad: false,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_IMAGES_FAILURE':
      {
        return {
          ...state,
          productImages: action.errorResponse,
          assetLoad: false,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_REQUEST':
      {
        return {
          ...state,
          assetLoad: true,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_SUCCESS':
      {
        return {
          ...state,
          productDocuments: action.documents,
          assetLoad: false,
        };
      }
    case 'GET_UNCERTIFIED_PRODUCT_DOCUMENTS_FAILURE':
      {
        return {
          ...state,
          productDocuments: action.errorResponse,
          assetLoad: false,
        };
      }
    case 'GET_DOCUMENT_TYPE_REQUEST':
      {
        return {
          ...state,
          isLoading: false,
        };
      }
    case 'GET_DOCUMENT_TYPE_SUCCESS':
      {
        return {
          ...state,
          documentTypeList: action.documentTypeLists,
          isLoading: false,
        };
      }
    case 'GET_DOCUMENT_TYPE_FAILURE':
      {
        return {
          ...state,
          documentTypeList: action.errorResponse,
          isLoading: false,
        };
      }
    case 'GET_IMAGE_TYPE_REQUEST':
      {
        return {
          ...state,
          isLoading: false,
        };
      }
    case 'GET_IMAGE_TYPE_SUCCESS':
      {
        return {
          ...state,
          imageShotTypeList: action.imageTypeLists,
          isLoading: false,
        };
      }
    case 'GET_IMAGE_TYPE_FAILURE':
      {
        return {
          ...state,
          imageShotTypeList: action.errorResponse,
          isLoading: false,
        };
      }
    case 'GET_ASSET_EXISTS_REQUEST':
      {
        return {
          ...state,
          isLoading: false,
        };
      }
    case 'GET_ASSET_EXISTS_SUCCESS':
      {
        return {
          ...state,
          existsStatus: action.successResponse,
          isLoading: false,
        };
      }
    /* ------------ uncertified product asset end ----------------------*/
    /* ------------ uncertified product asset  ----------------------*/
    case 'GET_DELETE_ASSET_REQUEST':
      {
        return {
          ...state,
          isLoading: false,
        };
      }
    case 'GET_DELETE_ASSET_SUCCESS':
      {
        return {
          ...state,
          deleteAssetStatus: action.successResponse,
          isLoading: false,
        };
      }
    case 'GET_DELETE_ASSET_FAILURE':
      {
        return {
          ...state,
          deleteAssetStatus: action.errorResponse,
          isLoading: false,
        };
      }
    /* ------------ uncertified product asset end ----------------------*/

    /* ----------------------- units-data-save ----------------------*/
    case 'GET_UNITS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_UNITS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          units: action.units,
          isLoading: false,
        };
      }
    case 'GET_UNITS_FAILURE_REQUEST':
      {
        return {
          ...state,
          units: action.error,
          isLoading: false,
        };
      }

    /* ----------------------- qualifiers-data-save ----------------------*/

    case 'GET_QUALIFIERS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_QUALIFIERS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          qualifiers: action.qualifiers,
          isLoading: false,
        };
      }
    case 'GET_QUALIFIERS_FAILURE_REQUEST':
      {
        return {
          ...state,
          qualifiers: action.error,
          isLoading: false,
        };
      }

    /* --Get Product Basic Data--*/
    case 'GET_PRODUCT_BASIC_DATA_REQUEST':
      {
        return {
          ...state,
          productLoader: true,
        };
      }

    case 'GET_PRODUCT_BASIC_DATA_SUCCESS':
      {
        return {
          ...state,
          productBasicData: action.basicData,
          productLoader: false,
        };
      }
    case 'GET_PRODUCT_BASIC_DATA_FAILURE':
      {
        return {
          ...state,
          productBasicData: action.error,
          productLoader: false,
        };
      }
    /* --Get Product Comments List--*/
    case 'GET_COMMENTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    /* --Get Image Shot type by Selected Product --*/
    case 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_SUCCESS':
      {
        return {
          ...state,
          assetType: action.data,
          isLoading: false,
        };
      }
    case 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_FAILURE':
      {
        return {
          ...state,
          assetType: action.error,
          isLoading: false,
        };
      }
    case 'GET_COMMENTS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          productCommentsList: action.commentsList,
          isLoading: false,
        };
      }
    case 'GET_COMMENTS_FAILURE_REQUEST':
      {
        return {
          ...state,
          productCommentsList: action.error,
          isLoading: false,
        };
      }
    /* --Get Recently Edited Product List--*/
    case 'GET_SDE_RECENT_EDITED_PRODUCTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_SDE_RECENT_EDITED_PRODUCTS_SUCCESS':
      {
        return {
          ...state,
          recentlyEditedProduct: action.recentlyEditedProduct,
          isLoading: false,
        };
      }
    case 'GET_SDE_RECENT_EDITED_PRODUCTS_FAILURE':
      {
        return {
          ...state,
          recentlyEditedProduct: action.error,
          isLoading: false,
        };
      }
    /* --Get Pending Approval Product List--*/
    case 'GET_SDE_LATEST_PENDING_APPROVAL_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_SDE_LATEST_PENDING_APPROVAL_SUCCESS':
      {
        return {
          ...state,
          pendingApprovalProduct: action.pendingApprovalProduct,
          isLoading: false,
        };
      }
    case 'GET_SDE_LATEST_PENDING_APPROVAL_FAILURE':
      {
        return {
          ...state,
          pendingApprovalProduct: action.error,
          isLoading: false,
        };
      }
    /* --Get Product Count --*/
    case 'GET_COUNT_PRODUCT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_COUNT_PRODUCT_SUCCESS_REQUEST':
      {
        return {
          ...state,
          countProduct: action.countProduct,
          isLoading: false,
        };
      }
    case 'GET_COUNT_PRODUCT_FAILURE_REQUEST':
      {
        return {
          ...state,
          countProduct: action.error,
          isLoading: false,
        };
      }
    /* ------------Get User language------------*/
    case 'GET_USER_LANGUAGE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_USER_LANGUAGE_SUCCESS':
      {
        return {
          ...state,
          userLanguage: action.language,
          isLoading: false,
        };
      }
    case 'GET_USER_LANGUAGE_FAILURE':
      {
        return {
          ...state,
          userLanguage: action.error,
          isLoading: false,
        };
      }

    /* --------------------------------handle-certified-product-images-documents-state------------------*/
    case 'GET_CERTIFIED_PRODUCT_IMAGES_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_IMAGES_SUCCESS':
      {
        return {
          ...state,
          productImages: action.images,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_IMAGES_FAILURE':
      {
        return {
          ...state,
          productImages: action.errorResponse,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_DOCUMENTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_DOCUMENTS_SUCCESS':
      {
        return {
          ...state,
          productDocuments: action.documents,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_DOCUMENTS_FAILURE':
      {
        return {
          ...state,
          productDocuments: action.errorResponse,
          isLoading: false,
        };
      }

    /* --------------------------------handle-certified-product-images-documents-state------------------*/
    case 'GET_POST_PUBLICATION_PRODUCT_IMAGES_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_POST_PUBLICATION_PRODUCT_IMAGES_SUCCESS':
      {
        return {
          ...state,
          postPublicationProductImages: action.images,
          isLoading: false,
        };
      }
    case 'GET_POST_PUBLICATION_PRODUCT_IMAGES_FAILURE':
      {
        return {
          ...state,
          postPublicationProductImages: action.errorResponse,
          isLoading: false,
        };
      }
    case 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_SUCCESS':
      {
        return {
          ...state,
          postPublicationProductDocuments: action.documents,
          isLoading: false,
        };
      }
    case 'GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_FAILURE':
      {
        return {
          ...state,
          postPublicationProductDocuments: action.errorResponse,
          isLoading: false,
        };
      }

    /* --------------------------------handle-certified-product-List------------------*/
    case 'GET_CERTIFIED_PRODUCT_LIST_REQUEST':
      {
        return {
          ...state,
          listLoader: true,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_LIST_SUCCESS':
      {
        return {
          ...state,
          products: action.productList,
          listLoader: false,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_LIST_FALIURE':
      {
        return {
          ...state,
          products: action.error,
          listLoader: false,
        };
      }

    /* ------------Get Marketing Data------------*/
    case 'GET_MARKETING_CONTENT_REQUEST':
      {
        return {
          ...state,
          // isLoading: true,
          marketingLoader: true,
        };
      }
    case 'GET_MARKETING_CONTENT_SUCCESS':
      {
        return {
          ...state,
          marketingData: action.marketingData,
          // isLoading: false,
          marketingLoader: false,
        };
      }
    case 'GET_MARKETING_CONTENT_FAILURE':
      {
        return {
          ...state,
          marketingData: action.error,
          // isLoading: false
          marketingLoader: false,
        };
      }

    /* --Get Image Shot type by Selected Product --*/
    case 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_SUCCESS':
      {
        return {
          ...state,
          assetType: action.data,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_FAILURE':
      {
        return {
          ...state,
          assetType: action.error,
          isLoading: false,
        };
      }

    /* --Get Image Shot type by Selected Product --*/

    /* --Get status --*/

    case 'GET_CERTIFIED_STATUS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CERTIFIED_STATUS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          dynamicStatusList: action.dynamicStatusList,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_STATUS_FAILURE_REQUEST':
      {
        return {
          ...state,
          dynamicStatusList: action.error,
          isLoading: false,
        };
      }

    /* ---------------------hande-get-licensed-subcodelist-------------------------*/
    case 'GET_LICENSED_SUBCODES_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_LICENSED_SUBCODES_SUCCESS_REQUEST':
      {
        return {
          ...state,
          licensedSubcodeList: action.licensed_sub_codeList.subcodes,
          publishDateFeatureSubcode: action.licensed_sub_codeList.futurePublishDateFeatureSubcodes,
          isLoading: false,
        };
      }

    case 'GET_LICENSED_SUBCODES_FAILURE_REQUEST':
      {
        return {
          ...state,
          licensedSubcodeList: [],
          publishDateFeatureSubcode: [],
          isLoading: false,
        };
      }

    /* ---------------------hande-get-licensed-subcodelist-------------------------*/
    case 'GET_SUBCODES_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_SUBCODES_SUCCESS_REQUEST':
      {
        return {
          ...state,
          subCodeList: action.sub_codeList,
          isLoading: false,
        };
      }

    case 'GET_SUBCODES_FAILURE_REQUEST':
      {
        return {
          ...state,
          subCodeList: [],
          isLoading: false,
        };
      }
    /* ---------------------hande-get-licensed-subcodelist-------------------------*/
    case 'GET_COMPLETENESS_REPORT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_COMPLETENESS_REPORT_SUCCESS_REQUEST':
      {
        return {
          ...state,
          completenessData: action.completenessData,
          isLoading: false,
        };
      }

    case 'GET_COMPLETENESS_REPORT_FAILURE_REQUEST':
      {
        return {
          ...state,
          completenessData: {},
          isLoading: false,
        };
      }

    /* ---------------------hande-get-Rsc-bar-data-------------------------*/
    case 'HANDLE_GET_DASHBOARD_RSC_COUNT':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'HANDLE_GET_DASHBOARD_RSC_COUNT_SUCCESS':
      {
        return {
          ...state,
          RscBarData: action.RscBarData,
          isLoading: false,
        };
      }

    case 'HANDLE_GET_DASHBOARD_RSC_COUNT_FAILURE':
      {
        return {
          ...state,
          RscBarData: action.error,
          isLoading: false,
        };
      }
    /* --Get Recently Edited Certified Product List--*/
    case 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_SUCCESS':
      {
        return {
          ...state,
          recentEditedCertifiedProduct: action.recentEditedCertifiedProduct,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_RECENT_EDITED_PRODUCTS_FAILURE':
      {
        return {
          ...state,
          recentEditedCertifiedProduct: action.error,
          isLoading: false,
        };
      }

    /* --Get Latest Certified Product List--*/
    case 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_SUCCESS':
      {
        return {
          ...state,
          latestCertifiedProduct: action.latestCertifiedProduct,
          isLoading: false,
        };
      }
    case 'GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_FAILURE':
      {
        return {
          ...state,
          latestCertifiedProduct: action.error,
          isLoading: false,
        };
      }

    // ------------------------------------return-filter-data-states-----------------------------------------
    case 'GET_FILTER_DATA_REQUEST':
      {
        return {
          ...state,
          filterLoader: true,
        };
      }

    case 'GET_FILTER_DATA_SUCCESS':
      {
        return {
          ...state,
          subCodeList: action.listFilterData.subcodes,
          targetMarketList: action.listFilterData.tms,
          targetUsage: TARGET_USAGE,
          dynamicStatusList: action.listFilterData.status,
          retailersList: action.listFilterData.retailers,
          futurePublishDateFeatureSubcodes: action.listFilterData.futurePublishDateFeatureSubcodes,
          filterLoader: false,
        };
      }
    case 'GET_FILTER_DATA_FAILURE':
      {
        return {
          ...state,
          filterLoader: false,
          futurePublishDateFeatureSubcodes: [],
        };
      }

    // ----------- Get Certified & Uncertified Categories tree view----------
    case 'GET_CERTIFIED_CATEGORY_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_CERTIFIED_CATEGORY_SUCCESS_REQUEST':
      {
        return {
          ...state,
          dynamicCategoryList: action.dynamicCategoryList,
          isLoading: false,
        };
      }

    case 'GET_CERTIFIED_CATEGORY_FAILURE_REQUEST':
      {
        return {
          ...state,
          dynamicCategoryList: action.error,
          isLoading: false,
        };
      }

    case 'GET_D_CATEGORY_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_D_CATEGORY_SUCCESS_REQUEST':
      {
        return {
          ...state,
          dynamicCategoryList: action.dynamicCategoryList,
          isLoading: false,
        };
      }

    case 'GET_D_CATEGORY_FAILURE_REQUEST':
      {
        return {
          ...state,
          dynamicCategoryList: action.error,
          isLoading: false,
        };
      }
    // ----------- End Certified & Uncertified Categories tree view----------

    // -------------------------------------------start-nbb-----------------------------------------------------

    case 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_REQUEST':
      {
        return {
          ...state,
          listLoader: true,
        };
      }
    case 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_SUCCESS':
      {
        return {
          ...state,
          products: action.productList,
          listLoader: false,
        };
      }
    case 'GET_AWAITING_BRANDBANK_CAPTURED_LIST_FALIURE':
      {
        return {
          ...state,
          products: action.error,
          listLoader: false,
        };
      }

    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_REQUEST':
      {
        return {
          ...state,
          productLoader: true,
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_SUCCESS':
      {
        return {
          ...state,
          productData: action.product,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: false },
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_FAILURE':
      {
        if (action?.error?.response && action?.error?.response?.status === 404 && action?.error?.response?.data?.msg) {
          return {
            ...state,
            productData: action.error,
            productLoader: false,
            isProductExist: { message: action.error.response.data.msg, isExist: false, isFetched: true },
          };
        }
        return {
          ...state,
          productData: action.error,
          productLoader: false,
          isProductExist: { message: '', isExist: true, isFetched: true },
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_SUCCESS':
      {
        return {
          ...state,
          productImages: action.images,
          isLoading: false,
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_IMAGES_FAILURE':
      {
        return {
          ...state,
          productImages: action.errorResponse,
          isLoading: false,
        };
      }

    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_SUCCESS':
      {
        return {
          ...state,
          productDocuments: action.documents,
          isLoading: false,
        };
      }
    case 'GET_AWAITING_APPROVAL_VIEW_PRODUCT_DOCUMENTS_FAILURE':
      {
        return {
          ...state,
          productDocuments: action.errorResponse,
          isLoading: false,
        };
      }

    case 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_SUCCESS':
      {
        return {
          ...state,
          dynamicCategoryList: action.dynamicCategoryList,
          isLoading: false,
        };
      }

    case 'GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_FAILURE':
      {
        return {
          ...state,
          dynamicCategoryList: action.error,
          isLoading: false,
        };
      }

    case 'HANDLE_IS_OPEN_VALIDATION_MODAL':
      {
        return {
          ...state,
          isValidation: action.data,
          validationData: action.validationData,
          isApprove: action.isApprove,
          isSaveAndApprove: action.isSaveAndApprove,
          productId: action.productId,
          retailers: action.retailers,
          template: action.template,
          componentTemplate: action.componentTemplate,
        };
      }

    case 'HANDLE_RENDER_ATTRIBUTE':
      {
        return {
          ...state,
          attributeInfo: action.data,
        };
      }

    case 'HANDLE_IGNORE_WARNINGS':
      {
        return {
          ...state,
          ignoreWarning: action.ignoreWarning,
        };
      }

    case 'HANDLE_CREATE_EDIT_VIEW':
      {
        return {
          ...state,
          createEditView: action.createEditView,
        };
      }

    // --------------------------------------handles-current-nbb-page-------------------------------------
    case 'HANDLE_NBB_CURRENT_PAGE':
      {
        return {
          ...state,
          currentNBBPage: action.currentNBBPage,
          offset: action.offset,
        };
      }
    // --------------------------------------create-------------------------------------
    case 'GET_SUBCODE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_SUBCODE_SUCCESS_REQUEST':
      {
        return {
          ...state,
          nbb_subcodeList: action.data,
          isLoading: false,
        };
      }
    case 'GET_SUBCODE_FAILURE_REQUEST':
      {
        return {
          ...state,
          nbb_subcodeList: action.error,
          isLoading: false,
        };
      }

    /* --------------------------------handle-certified-product-List------------------*/
    case 'GET_PRODUCT_SEARCH_REQUEST':
      {
        return {
          ...state,
          listLoader: true,
        };
      }
    case 'GET_PRODUCT_SEARCH_SUCCESS_REQUEST':
      {
        return {
          ...state,
          hybridProducts: action.data,
          listLoader: false,
        };
      }
    case 'GET_PRODUCT_SEARCH_FAILED_REQUEST':
      {
        return {
          ...state,
          hybridProducts: action.error,
          listLoader: false,
        };
      }

    case 'GET_ADVANCE_FILTER_DROPDOWN_REQUEST':
      {
        return {
          ...state,
          advanceSearchLoader: true,
          isLoading: true,
        };
      }
    case 'GET_ADVANCE_FILTER_DROPDOWN_SUCCESS_REQUEST':
      {
        return {
          ...state,
          advanceFilterData: action.data,
          advanceSearchLoader: false,
          isLoading: false,
        };
      }
    case 'GET_ADVANCE_FILTER_DROPDOWN_FAILURE_REQUEST':
      {
        return {
          ...state,
          advanceFilterData: action.error,
          advanceSearchLoader: false,
          isLoading: false,
        };
      }

    case 'GET_BASIC_TEMPLATE_DATA_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_BASIC_TEMPLATE_DATA_SUCCESS':
      {
        return {
          ...state,
          basicTempData: action.data,
          isLoading: false,
        };
      }

    case 'GET_BASIC_TEMPLATE_DATA_FAILURE':
      {
        return {
          ...state,
          basicTempData: action.error,
          isLoading: false,
        };
      }
    case 'GET_CIN_LOG_MSG_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CIN_LOG_MSG_SUCCESS_REQUEST':
      {
        return {
          ...state,
          cinLogMsg: action.data,
          isLoading: false,
        };
      }

    case 'GET_CIN_LOG_MSG_FAILURE_REQUEST':
      {
        return {
          ...state,
          cinLogMsg: action.error,
          isLoading: false,
        };
      }
    case 'GET_CIC_LOG_MSG_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CIC_LOG_MSG_SUCCESS_REQUEST':
      {
        return {
          ...state,
          cicLogMsg: action.data,
          isLoading: false,
        };
      }
    case 'GET_CIC_LOG_MSG_FAILURE_REQUEST':
      {
        return {
          ...state,
          cicLogMsg: action.error,
          isLoading: false,
        };
      }

    case 'GET_PUBLISH_STATUS_LOG_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_PUBLISH_STATUS_LOG_SUCCESS_REQUEST':
      {
        return {
          ...state,
          publishStatusLog: action.data,
          isLoading: false,
        };
      }
    case 'GET_PUBLISH_STATUS_LOG_FAILURE_REQUEST':
      {
        return {
          ...state,
          publishStatusLog: action.error,
          isLoading: false,
        };
      }

    case 'GET_HYBRID_COMPLETENESS_SCORE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_HYBRID_COMPLETENESS_SCORE_SUCCESS_REQUEST':
      {
        return {
          ...state,
          completenessData: action.completenessData,
          isLoading: false,
        };
      }

    case 'GET_HYBRID_COMPLETENESS_SCORE_FAILURE_REQUEST':
      {
        return {
          ...state,
          completenessData: {},
          isLoading: false,
        };
      }

    case 'GET_HYBRID_PRODUCT_COUNT_BY_STATUS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_HYBRID_PRODUCT_COUNT_BY_SUCCESS_REQUEST_STATUS':
      {
        return {
          ...state,
          countProduct: action.countProduct,
          isLoading: false,
        };
      }
    case 'GET_HYBRID_PRODUCT_COUNT_BY_FAILURE_REQUEST_STATUS':
      {
        return {
          ...state,
          countProduct: action.error,
          isLoading: false,
        };
      }

    case 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_SUCCESS_REQUEST':
      {
        return {
          ...state,
          countProductChannel: action.countProductChannel,
          isLoading: false,
        };
      }
    case 'GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_FAILURE_REQUEST':
      {
        return {
          ...state,
          countProductChannel: action.error,
          isLoading: false,
        };
      }

    case 'GET_NBB_RECENT_EDITED_PRODUCTS_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_NBB_RECENT_EDITED_PRODUCTS_SUCCESS_REQUEST':
      {
        return {
          ...state,
          recentEditedNbbProduct: action.recentEditedNbbProduct,
          isLoading: false,
        };
      }
    case 'GET_NBB_RECENT_EDITED_PRODUCTS_FAILURE_REQUEST':
      {
        return {
          ...state,
          recentEditedNbbProduct: action.error,
          isLoading: false,
        };
      }

    case 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_SUCCESS_REQUEST':
      {
        return {
          ...state,
          brandbankCapturedData: action.brandbankCapturedData,
          isLoading: false,
        };
      }
    case 'GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_FAILURE_REQUEST':
      {
        return {
          ...state,
          brandbankCapturedData: action.error,
          isLoading: false,
        };
      }

    case 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_SUCCESS_REQUEST':
      {
        return {
          ...state,
          countProductValidation: action.countProductValidation,
          isLoading: false,
        };
      }
    case 'GET_HYBRID_PRODUCT_VALIDATION_COUNT_FAILURE_REQUEST':
      {
        return {
          ...state,
          countProductValidation: action.error,
          isLoading: false,
        };
      }

    case 'GET_DASHBOARD_RSC_PRODUCT_COUNT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_DASHBOARD_RSC_PRODUCT_COUNT_SUCCESS_REQUEST':
      {
        return {
          ...state,
          RscBarProductData: action.RscBarProductData,
          isLoading: false,
        };
      }
    case 'GET_DASHBOARD_RSC_PRODUCT_COUNT_FAILURE_REQUEST':
      {
        return {
          ...state,
          RscBarProductData: action.error,
          isLoading: false,
        };
      }

    case 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }

    case 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_SUCCESS_REQUEST':
      {
        return {
          ...state,
          countByCertification: action.countByCertification,
          isLoading: false,
        };
      }
    case 'GET_DASHBOARD_COUNT_BY_CERTIFICATION_FAILURE_REQUEST':
      {
        return {
          ...state,
          countByCertification: action.error,
          isLoading: false,
        };
      }

    case 'SET_NBB_PRODUCT_FILTER':
      {
        return {
          ...state,
          nbb_filter_data: action.data,
        };
      }
    /** -------NBB Target Market -------*/
    case 'GET_NBB_TARGETMARKET_LIST_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_NBB_TARGETMARKET_LIST_SUCCESS':
      {
        return {
          ...state,
          nbbTargetMarketList: action.data,
          isLoading: false,
        };
      }

    case 'GET_NBB_TARGETMARKET_LIST_FAILURE':
      {
        return {
          ...state,
          nbbTargetMarketList: action.error,
          isLoading: false,
        };
      }
    case 'HANDLE_RETURN_TO_PRODUCT_LIST':
      {
        return {
          ...state,
          returnToProductList: action.returnToProductList,
        };
      }
    /* Certified Image Download - Image Shot Types */
    case 'GET_NBB_PRODUCT_IMAGE_TYPE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_NBB_PRODUCT_IMAGE_TYPE_SUCCESS':
      {
        return {
          ...state,
          nbbAssetType: action.data,
          isLoading: false,
        };
      }
    case 'GET_NBB_PRODUCT_IMAGE_TYPE_FAILURE':
      {
        return {
          ...state,
          nbbAssetType: action.error,
          isLoading: false,
        };
      }
    /* Certified Image Download - Image Shot Types */

    // ------------------------------------------reports-state---------------------------------------------------------
    // --------------------------publication-summary-report----------------------
    case 'GET_PUBLICATION_SUMMARY_REPORTS':
      {
        return {
          ...state,
          reportLoader: true,
        };
      }
    case 'GET_PUBLICATION_SUMMARY_REPORTS_SUCCESS':
      {
        return {
          ...state,
          publicationReportData: action.data,
          reportLoader: false,
        };
      }
    case 'GET_PUBLICATION_SUMMARY_REPORTS_FAILURE':
      {
        return {
          ...state,
          publicationReportData: action.error,
          reportLoader: false,
        };
      }

    // ------------------publication-detail-report---------------------------------
    case 'GET_PUBLICATION_DETAIL_REPORTS':
      {
        return {
          ...state,
          reportLoader: true,
        };
      }
    case 'GET_PUBLICATION_DETAIL_REPORTS_SUCCESS':
      {
        return {
          ...state,
          publicatonDetailReport: action.data,
          reportLoader: false,
        };
      }
    case 'GET_PUBLICATION_DETAIL_REPORTS_FAILURE':
      {
        return {
          ...state,
          publicatonDetailReport: action.error,
          reportLoader: false,
        };
      }

    // ------------------merge-detail-report---------------------------------
    case 'GET_MERGE_DETAIL_REPORT':
      {
        return {
          ...state,
          reportLoader: true,
        };
      }
    case 'GET_MERGE_DETAIL_REPORT_SUCCESS':
      {
        return {
          ...state,
          mergeDtailReport: action.data,
          reportLoader: false,
        };
      }
    case 'GET_MERGE_DETAIL_REPORT_FAILURE':
      {
        return {
          ...state,
          mergeDtailReport: action.error,
          reportLoader: false,
        };
      }

    // --------------------nbb-ssa-state-----------------------------------------
    case 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_SUCCESS':
      {
        return {
          ...state,
          NbbSSAData: action.data,
          isLoading: false,
        };
      }
    case 'GET_SUPPLIER_SPECIFIC_ATTRIBUTE_FAILURE':
      {
        return {
          ...state,
          NbbSSAData: null,
          isLoading: false,
        };
      }

    /* ---------------------hande-contentHealth reports-------------------------*/
    case 'GET_CH_PLUS_REPORT_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_CH_PLUS_REPORT_SUCCESS_REQUEST':
      {
        return {
          ...state,
          contentHealthAndRankData: action.healthRankData,
          isLoading: false,
        };
      }

    case 'GET_CH_PLUS_REPORT_FAILURE_REQUEST':
      {
        return {
          ...state,
          contentHealthAndRankData: {},
          isLoading: false,
        };
      }

    case 'GET_PRODUCT_RECALCULATE_SCORE':
      {
        return {
          ...state,
          isLoading: false,
        };
      }

    // ---------------------------image topup-state--------------------------
    // ------------------------------get-saved-layout--------------------------------
    case 'GET_PRODUCT_TOPUP_LAYOUTS':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_PRODUCT_TOPUP_LAYOUTS_SUCCESS':
      {
        return {
          ...state,
          imageTopupSavedLayouts: action.data,
          isLoading: false,
        };
      }
    case 'GET_PRODUCT_TOPUP_LAYOUTS_FAILURE':
      {
        return {
          ...state,
          imageTopupSavedLayouts: [],
          isLoading: false,
        };
      }
    // ------------------------------get user uploaded asset--------------------------------
    case 'GET_USER_UPLOADED_ASSET_REQUEST':
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case 'GET_USER_UPLOADED_ASSET_SUCCESS':
      {
        return {
          ...state,
          userUploadedAsset: action.data,
          isLoading: false,
        };
      }
    case 'GET_USER_UPLOADED_ASSET_FAILURE':
      {
        return {
          ...state,
          userUploadedAsset: [],
          isLoading: false,
        };
      }

    // ------------------------------flexible extract--------------------------------
    case 'GET_CERTIFIED_ATTRIBUTE_DATA_REQUEST':
      {
        return {
          ...state,
          isLoading: true
        };
      }
    case 'GET_CERTIFIED_ATTRIBUTE_DATA_SUCCESS':
      {
        return {
          ...state,
          certifiedAttributeData: action.data,
          isLoading: false
        };
      }
    case 'GET_CERTIFIED_ATTRIBUTE_DATA_FAILURE':
      {
        return {
          ...state,
          certifiedAttributeData: [],
          isLoading: false
        };
      }

    case 'GET_IMAGE_CONFIGURATIONS_REQUEST':
      {
        return {
          ...state,
          isLoading: true
        };
      }
    case 'GET_IMAGE_CONFIGURATIONS_SUCCESS':
      {
        return {
          ...state,
          imageConfiguration: action.data?.configData,
          imageDimensionOpt: action.data?.imageDimensionOpt,
          isLoading: false
        };
      }
    case 'GET_IMAGE_CONFIGURATIONS_FAILURE':
      {
        return {
          ...state,
          imageConfiguration: null,
          imageDimensionOpt: [],
          isLoading: false
        };
      }

    case 'HANDLE_PREV_NEXT_REQUEST':
      {
        return {
          ...state,
          prevNextLoading: true
        };
      }
    case 'HANDLE_PREV_NEXT_SUCCESS':
      {
        return {
          ...state,
          prevNextProductData: action.data,
          prevNextLoading: false
        };
      }
    case 'HANDLE_PREV_NEXT_FAILURE':
      {
        return {
          ...state,
          prevNextProductData: [],
          prevNextLoading: false
        };
      }

    default:
      return state;
  }
};
