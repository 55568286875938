import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import * as Constants from '../../../../constant/constant';
import { checkProductLicense } from '../../../../Utils/userPermissions';
import {
  GET_TEMPLATE_SUCCESS_REQUEST,
  GET_TEMPLATE_FAILURE_REQUEST,
  GET_PRODUCT_DATA_SUCCESS_REQUEST,
  GET_PRODUCT_DATA_FALIURE_REQUEST,
  HANDLE_PREV_NEXT_SUCCESS,
  HANDLE_PREV_NEXT_FAILURE
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------get-Template-data-----------------------------------*/

export function getTemplate(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getTemplateSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const response = yield call(getTemplate, `/get-template-data?pimcoreId=${action.data.productId}`, token);
    const template_data = response.data;
    yield put({ type: GET_TEMPLATE_SUCCESS_REQUEST, template_data });
  } catch (error) {
    yield put({ type: GET_TEMPLATE_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-product-data-----------------------------------*/

export function getProductData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductDataSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getProductData, `/sde/get-product-data?pimcoreId=${action.data.id}`, token);
    if (action.data.handleSetIsDataChanged) {
      action.data.handleSetIsDataChanged(false);
    }
    const product = response.data;
    yield put({ type: GET_PRODUCT_DATA_SUCCESS_REQUEST, product });
    if (!checkProductLicense(product.productLicense, Constants.LICENSE_SERVICES.SDE) && action.data.props.match.url === `/uncertified/edit/${action.data.id}`) {
      action.data.props.history.push(`/uncertified/details/${action.data.id}`);
    }
  } catch (error) {
    yield put({ type: GET_PRODUCT_DATA_FALIURE_REQUEST, error });
  }
}

/* -------------------------Get Prev & Next Product -------------------- */
export function getPrevNextProductData(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* handlePrevNextProductData(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getPrevNextProductData, action.data.url, action.data.payload, token);
    const data = response?.data?.data || [];
    yield put({ type: HANDLE_PREV_NEXT_SUCCESS, data });
  } catch (error) {
    yield put({ type: HANDLE_PREV_NEXT_FAILURE, error });
  }
}
