import { Img } from 'react-image';
import React from 'react';
import * as Constants from '../constant/constant';
import { padLeadingZeros } from './PadLeadingZeros';
import { isTPDEnable } from './Nbb/GDSNDataTypesConfigration';


export const randomNumbers = (length = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const groupByKey = function (xs, key) {
  if (Object.keys(xs).length < 1) return {};
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getImageLoader = function () {
  return <Img alt="loading.." src="/assets/images/icons-svg/spinner.svg" />;
};

export const getDefaultImage = function (height = 40, width = 40) {
  return (
    <Img
      height={height}
      width={width}
      alt="no image"
      src={Constants.DEFAULT_IMAGE_THUMBNAIL}
    />
  );
};

export const isEmptyObjet = (data) => {
  let isEmpty = false;
  Object.size = function (obj) {
    let size = 0;
    let key;
    for (key in obj) {
      if (Object.keys(obj[key]).length != 0) {
        const objValue = obj[key];
        for (let i = 0; i < Object.keys(objValue).length; i++) {
          if (objValue[i] && objValue[i].trim() !== '') {
            size++;
          }
        }
      }
    }
    return size;
  };
  const size = Object.size(data);
  if (size) {
    isEmpty = true;
  }
  return isEmpty;
};

export const getParsedScore = (value, decimalPlaces = 0) => {
  if (value) {
    return parseFloat(+value).toFixed(decimalPlaces);
  }
  return 0;
};

export const getCertifiedURL = (productStatus, productId, serviceName) => {
  let URL = '';
  const statusData = Constants.PRODUCT_STATUS;
  if (serviceName === Constants.SERVICES_NAMES.CERTIFIED) {
    switch (productStatus) {
      case statusData.AWAITING_APPROVAL:
      case statusData.AWAITING_CORE_DATA_APPROVAL:
      case statusData.OUTSTANDING_REJECTION:
      case statusData.AWAITING_PRE_APPROVAL:
        URL = `/certified/approval/${productId}`;
        break;
      default:
        URL = `/certified/details/${productId}`;
    }
    return URL;
  }
  if (serviceName === Constants.SERVICES_NAMES.NBBCORE) {
    if (productStatus === statusData.AWAITING_CORE_DATA_APPROVAL) return (URL = `/awaiting-brandBank-captured-data-review/approval/${productId}`);
  }
};

export const getNbbURL = (productStatus, productId, serviceName) => {
  let URL = '';
  const statusData = Constants.PRODUCT_STATUS;
  const statusName = Constants.NBB_PRODUCT_STATUS;
  if (serviceName === Constants.SERVICES_NAMES.NBB) {
    switch (productStatus) {
      case statusData.AWAITING_APPROVAL:
        URL = `/nbb-products/approval/${productId}`;
        break;
      case 'deleted':
        URL = '/nbb-products';
        break;
      case statusName.Draft:
      case statusName.published_with_cic_error:
      case statusName.Rejected:
      case statusName.PUBLISHED_WITH_CIC_ERROR:
      case statusName['Queued for Publishing']:
      case statusName.Published:
      case statusName['Publishing Error']:
        URL = `/nbb-products/edit/${productId}`;
        break;
      case statusName['Action Required']:
        URL = `/nbb-products/action-required/${productId}`;
        break;
      default:
        URL = `/nbb-products/edit/${productId}`;
    }
    return URL;
  }
  if (serviceName === Constants.SERVICES_NAMES.NBBCORE) {
    if (productStatus === statusData.AWAITING_CORE_DATA_APPROVAL) return (URL = `/awaiting-brandBank-captured-data-review/approval/${productId}`);
  }
};

export const getDcTime = () => {
  const d = new Date();
  return d.getTime();
};

// ------------------------------wrap-long-text-tooltip or bar chart-------------------------------------
export const wordWrap = (str, maxWidth) => {
  const newLineStr = '\n';
  let res = '';
  while (str.length > maxWidth) {
    let found = false;
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res += [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }
  return res + str;
};

const testWhite = (x) => {
  const white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

export const ucFirst = (string) => {
  let txt = '';
  if (string) {
    txt = string.charAt(0).toUpperCase() + string.slice(1);
  }
  return txt;
};

export const booltostring = (value) => {
  if (value == null || value == undefined) {
    return;
  }
  return value.toString();
};
export const checkForValue = (attributeArr, v) => {
  if (v?.pimRSC == 1 || (v?.gdsnDataType == 'CodeList')) {
    for (let j = 0; j < attributeArr?.length; j++) {
      let foundValue = false;
      if (Array.isArray(attributeArr[j]?.value)) {
        for (let k = 0; k < attributeArr[j]?.value?.length; k++) {
          if (
            typeof (attributeArr[j]?.value)[k] === 'object'
            && (attributeArr[j]?.value)[k] !== null
            && !Array.isArray((attributeArr[j]?.value)[k])
            && (attributeArr[j]?.value)[k]?.value
          ) {
            foundValue = true;
          }
        }
      } else if (
        typeof attributeArr[j] === 'object'
        && attributeArr[j] !== null
        && !Array.isArray(attributeArr[j])
        && attributeArr[j]?.value
      ) {
        foundValue = true;
      }
      if (foundValue) {
        return true;
      }
    }
  } else {
    for (let i = 0; i < attributeArr?.length; i++) {
      if (
        typeof attributeArr[i] === 'object'
        && attributeArr[i] !== null
        && !Array.isArray(attributeArr[i])
        && attributeArr[i]?.value
      ) {
        return true;
      }
    }
  }
};

const checkValue = (data) => {
  for (let i = 0; i < data?.length; i++) {
    let valueFound = false;
    for (let j = 0; j < data[i]?.length; j++) {
      if (data[i][j]) {
        valueFound = true;
      }
    }
    if (valueFound) {
      return true;
    }
  }
};
const checkFreeTextNutritionData = (data) => {
  if (data?.headings) {
    for (let i = 0; i < data.headings?.length; i++) {
      if (data.headings[i]) {
        return true;
      }
    }
  }
  if (data?.nutrients) {
    for (var j = 0; j < data.nutrients?.length; j++) {
      if (data.nutrients[j]?.name) {
        return true;
      }
    }
    for (let k = 0; k < data.nutrients?.length; k++) {
      let valueExist = false;
      if (data.nutrients[k]?.values?.length > 0) {
        for (var j = 0; j < data.nutrients[k].values?.length; j++) {
          if (data.nutrients[k].values[j]) {
            valueExist = true;
          }
        }
        if (valueExist) {
          return true;
        }
      }
    }
  }
};

const checkIngredients = (data) => {
  if (data && Array.isArray(data)) {
    const filterData = data.filter((item) => item && typeof item === 'string');
    if (filterData?.length > 0) {
      return true;
    }
    return false;
  }
};

const checkValuePresent = (data) => {
  if (data && Array.isArray(data)) {
    const checkForValue = data?.filter((item) => typeof item === 'object' && Object.keys(item).includes('value') && item?.value);
    if (checkForValue?.length > 0) {
      return true;
    }
    return false;
  }
};

export const visitNodes = (getPath, path, stack = []) => {
  if (
    (getPath && typeof getPath === 'object' && Object.keys(getPath).length > 0)
    || (Array.isArray(getPath) && getPath.length > 0)
  ) {
    for (const key in getPath) {
      if (
        key == 'value'
        && getPath.value
        && (!Array.isArray(getPath?.value)
          || (Array.isArray(getPath?.value) && checkIngredients(getPath.value) || Array.isArray(getPath?.value)
            && getPath?.value?.length > 0
            && Array.isArray(getPath?.value[0])
            && checkValue(getPath?.value))
          || (Array.isArray(getPath?.value)
            && getPath?.value?.length > 0
            && (checkValuePresent(getPath?.value)))) || (key == 'headings' && checkFreeTextNutritionData(getPath))
      ) {
        path.push(stack.join('/').replace(/(?:\.)(\d+)(?![a-z_])/gi, '/$1'));
      } else {
        visitNodes(getPath[key], path, [...stack, key]);
      }
    }
  }
};

export const getIconURL = (productStatus, isLocked = false) => {
  let URL = '';
  if (isLocked) {
    return '/assets/images/icons-svg/lock-yellow.svg';
  }

  switch (productStatus) {
    case 'queued_for_publishing':
      URL = '/assets/images/icons-svg/lock-yellow.svg';
      break;
    case 'rejected':
      URL = '/assets/images/icons-svg/hierarchy/reject.svg';
      break;
    case 'published':
      URL = '/assets/images/icons-svg/hierarchy/publish.svg';
      break;
    case 'published_with_cic_error':
      URL = '/assets/images/icons-svg/hierarchy/publishandreject.svg';
      break;
    case 'delisted':
      URL = '/assets/images/icons-svg/hierarchy/discontinued.svg';
      break;
    default:
      URL = '/assets/images/icons-svg/hierarchy/draft.svg';
  }
  return URL;
};

export const getRetailerName = (retailerName = null, id, retailersList = []) => {
  if (retailerName) {
    return retailerName;
  } else {
    if (id) {
      const retailer = retailersList?.filter((item) => item?.companyId == id);
      if (retailer && retailer?.length > 0) {
        return retailer[0]?.label;
      }
      return '';
    }
    return retailersList?.filter((item) => item?.companyId == 'default')[0]?.label;
  }
};

export const publicationTypeLabel = (data) => {
  const updatedBasicTemplate = data?.children?.map((item) => (item?.name == 'isPublic' ? { ...item, label: Constants.LABELS.PUBLICATION_TYPE } : { ...item }));
  data.children = updatedBasicTemplate;
  return data;
};

export const checkTPDAtClassLevel = (data) => {
  if (data
    && data.type == 'class'
    && data.isMinViable
    && data.isMinViable == '1'
    && isTPDEnable(data.pimRSC)) {
    return true;
  }
  return false;
};

export const getFormattedGLN = (value) => {
  const regEx = /\r\n|\r|\n|(,)\s*|\s/;
  const gtinData = value;
  const gtinArr = gtinData.split(regEx);
  const data = gtinArr.filter((val) => {
    if (val && val !== ',') {
      return val;
    }
  });
  const newArr = data.map((d) => {
    if (d && d.length < 13) {
      return padLeadingZeros(d, 13);
    }
    return d;
  });
  return newArr.join('\n');
};

export const checkCdbDefaultRetailerExits = (cdbRetailers, dataRecipientList) => {
  const cdbDefaultRetailers = cdbRetailers?.length != 0 && cdbRetailers?.map((v) => v.gln);
  const isCdbGlnExits = dataRecipientList?.length != 0
    && dataRecipientList?.some((v) => cdbDefaultRetailers?.includes(v.gln));
  return isCdbGlnExits;
};

export const translateRetailersData = (retailers, t) => {
  return retailers?.map(({ value, label, ...rest }) => ({
    ...rest,
    value,
    label: value === 'default' || value === '' ? t(label) : label,
  })) || [];
}

export const capitalizeAndTranslate = (str, t) => {
  if (!str) return ''; // Handle empty strings or null values
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return t(capitalized); // Translate after capitalizing
};

export const findCategoryTitlesByValue = (targetValue, categories) => {

  if (!targetValue) return ''; // Handle empty strings or null values  

  let foundTitle = '';

  const searchCategories = (categoryList) => {
    for (const category of categoryList) {
      if (category.value === targetValue) {
        foundTitle = category.title;
        return; // Exit the function early since we've found the target
      }
      if (category.children && !foundTitle) {
        searchCategories(category.children);
      }
      if (foundTitle) return; // Exit if title is already found
    }
  };
  searchCategories(categories);
  return foundTitle;
};

export const findCategoryTitlesFromCategoryByValue = (targetValue, categories) => {
  if (!targetValue) return ''; // Handle empty strings or null values  

  let foundTitle = '';

  const searchCategories = (categoryList) => {
    for (const category of categoryList) {
      if (category.value === targetValue) {
        foundTitle = category.title;
        return; // Exit the function early since we've found the target
      }
      if (category.children && !foundTitle) {
        searchCategories(category.children);
      }
      if (foundTitle) return; // Exit if title is already found
    }
  };
  searchCategories(categories);
  return foundTitle;
};

export function separateLastParenthesesData(message) {
  const regex = /(.*)\s\(([^)]+)\)$/;  // Regular expression to match the message and text inside the last parentheses
  const match = message.match(regex);

  if (match) {
    const mainMessage = match[1];  // Text before the last parentheses
    const lastParenthesesData = match[2];  // Text inside the last parentheses
    return { mainMessage, lastParenthesesData };
  } else {
    return { mainMessage: message, lastParenthesesData: '' };  // If no parentheses found
  }
}

export function separateLastParenthesesDataNTranslate(message, t) {
  const { mainMessage, lastParenthesesData } = separateLastParenthesesData(message);

  const translatedMain = t(mainMessage);
  if (!lastParenthesesData) return translatedMain;

  return `${translatedMain} (${t(lastParenthesesData)})`;
}

export function separateLastParenthesesDataNTranslateMainOnly(message, t) {
  const { mainMessage, lastParenthesesData } = separateLastParenthesesData(message);

  const translatedMain = t(mainMessage);  // Translate only the mainMessage
  if (!lastParenthesesData) return translatedMain;  // If there's no lastParenthesesData, return the translated mainMessage

  return `${translatedMain} (${lastParenthesesData})`;  // Return translated mainMessage and original lastParenthesesData
}


export const translateAndJoin = (string, t) => {
  return string
    .split(",")  // Split the string by commas
    .map((item) => t(item.trim()))  // Trim whitespace and apply `t()` to each item
    .join(", ");  // Join them back with a comma and space
};

export const translateLabelNAddValueInParenthesis = (options, t) => {
  return options.map((data) => ({
    ...data,
    label: `${t(data.label)} (${data.value})`
  }));
}

export const translateLabelKey = (options, t) => options.map(option => ({
  ...option,
  label: t(option.label)
}));